<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      *ngIf="isHandset$ | async"
      (click)="toggleSideNav()"
    >
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>

    <img
      class="logo"
      src="assets/images/logo/MasjidsInfoBlack.svg"
      alt="Masjids info logo"
      [style.margin-inline]="(isHandset$ | async) && 'auto'"
    />
    <div class="spacing"></div>

    <button
      *ngIf="userLoggedIn | async"
      routerLink="/auth/profile"
      mat-icon-button
      aria-label="Profile"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="account icon"
        fontIcon="account_circle"
        [ariaSetSize]="50"
        color="accent"
      ></mat-icon>
    </button>
    <button *ngIf="userLoggedIn | async" (click)="logout()" mat-icon-button>
      <mat-icon
        aria-hidden="false"
        aria-label="logout icon"
        fontIcon="logout"
        [ariaSetSize]="50"
        color="accent"
      ></mat-icon>
    </button>

    <mat-slide-toggle
      (change)="switchMode($event)"
      color="accent"
      class="mat-slide-toggle"
    ></mat-slide-toggle>

    <button
      mat-icon-button
      [matMenuTriggerFor]="language"
      aria-label="Language icon-button with a menu"
    >
      <mat-icon color="accent">language</mat-icon>
    </button>
    <mat-menu #language="matMenu">
      <button
        mat-menu-item
        *ngFor="let language of languages"
        (click)="languagePicked(language.code)"
      >
        <span class="language-option">
          <img
            src="https://flagcdn.com/{{ language.country.toLowerCase() }}.svg"
            width="35"
            alt="{{ language.nativeName }}"
          />
          {{ language.nativeName }}
        </span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
