import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AppwriteException, Models } from 'appwrite'
import {
    Observable,
    catchError,
    concatMap,
    finalize,
    forkJoin,
    map,
    mergeMap,
    of,
    switchMap,
    tap,
    throwError,
    withLatestFrom,
} from 'rxjs'

import {
    PhotoInterface,
    PhotoType,
} from 'src/app/shared/interfaces/Image.interface'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { LessonsService } from 'src/app/shared/services/lessons.service'
import { LogService } from 'src/app/shared/services/log.service'
import { MasjidDocumentService } from 'src/app/shared/services/masjid.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import Utils from 'src/app/shared/services/utils'
import { PhotoOperation } from '../../interfaces/masjid.state.interface'
import {
    MasjidDataInterface,
    MasjidLessonsInterface,
} from '../../interfaces/masjidData.interface'
import {
    updateExistingMasjid,
    updateExistingMasjidFailure,
    updateExistingMasjidSuccess,
} from '../actions/edit.masjid.actions'
import { editMasjidUpdateMasjidSelector } from '../selectors'
import {
    loadAdminMasjids,
    loadAdminMasjidsFailure,
    loadAdminMasjidsSuccess,
    registerMasjid,
    registerMasjidFailure,
    registerMasjidSuccess,
} from '../actions/masjid.actions'
import { AuthService } from 'src/app/shared/services/auth.service'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'

@UntilDestroy()
@Injectable()
export class MasjidEffects {
    constructor(
        private _store: Store,
        private _masjidService: MasjidDocumentService,
        private _lessonsService: LessonsService,
        private _logService: LogService,
        private _notificationService: NotificationService,
        private actions$: Actions,
        private _router: Router,
        private _authService: AuthService,
        private _fileStorageService: FileStorageService
    ) {}

    registerMasjid$ = createEffect(() =>
        this.actions$.pipe(
            ofType(registerMasjid),
            switchMap(({ masjidData, filesToUpload, lessons }) => {
                this._notificationService.loading('Registering Masjid...')
                // Step 1: Upload files
                const fileUpload$ = filesToUpload?.length
                    ? this.handleUploadFiles(filesToUpload)
                    : of({ masjidPhotoIds: [], leaderPhotoId: null })

                return fileUpload$.pipe(
                    switchMap(({ masjidPhotoIds, leaderPhotoId }) => {
                        // Step 2: Create lessons
                        const lessonsCreation$ = lessons?.length
                            ? this.handleLessonsCreation(lessons)
                            : of({ lessonIds: [] })
                        return lessonsCreation$.pipe(
                            switchMap(({ lessonIds }) => {
                                // Step 3: Update masjidData
                                const updatedMasjidData = {
                                    ...masjidData,
                                    ImagesIds: masjidPhotoIds,
                                    leaderImageId: leaderPhotoId,
                                    lessonIds: lessonIds,
                                }
                                // Step 4: Save final masjid data
                                return this._masjidService
                                    .createMasjidDocument(updatedMasjidData)
                                    .pipe(
                                        map((createdMasjid) => {
                                            this._notificationService.notifySuccess(
                                                'Masjid registered successfully!'
                                            )
                                            return registerMasjidSuccess({
                                                masjid: this._masjidService.convertDocumentToInterface(
                                                    createdMasjid
                                                ),
                                            })
                                        }),
                                        catchError((error) => {
                                            this._notificationService.notifyFailure(
                                                'Masjid registration failed!'
                                            )
                                            return of(
                                                registerMasjidFailure({ error })
                                            )
                                        })
                                    )
                            })
                        )
                    })
                )
            }),
            catchError((error) => {
                console.error('Unexpected error in effect:', error)
                this._notificationService.removeLoading()
                this._notificationService.notifyFailure(
                    'An unexpected error occurred!'
                )
                return of(registerMasjidFailure({ error }))
            })
        )
    )
    registerMasjidFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(registerMasjidFailure),
                tap((action) => {
                    console.log('Error submitting masjid data:', action.error)
                    this._notificationService.notifyFailure(
                        `Failed to register Masjid: ${Utils.formatException(
                            action.error
                        )}`
                    )
                })
            ),
        { dispatch: false }
    )

    redirectAfterSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(registerMasjidSuccess),
                tap(({ masjid }) => {
                    this._notificationService.removeLoading()
                    setTimeout(() => {
                        this._router.navigate(['/masjid/details', masjid.id])
                    }, 1000)
                })
            ),
        { dispatch: false }
    )
    handleUploadFiles(
        photos: PhotoInterface[]
    ): Observable<{ masjidPhotoIds: string[]; leaderPhotoId: string | null }> {
        if (!photos || photos.length === 0) {
            return of({ masjidPhotoIds: [], leaderPhotoId: null })
        }

        return forkJoin(
            photos.map((photo) =>
                this._fileStorageService
                    .uploadFile(photo.photo, photo.id, photo.bucketId)
                    .pipe(
                        catchError((error) => {
                            console.error(
                                `Failed to upload photo: ${photo.name}`,
                                error
                            )
                            throw error // Stop the process and propagate error
                        })
                    )
            )
        ).pipe(
            map((uploadedFiles) => {
                const masjidPhotoIds = Utils.mapUploadedPhotosToIds(
                    uploadedFiles,
                    photos,
                    PhotoType.Masjid
                )

                const leaderPhotoId =
                    Utils.mapUploadedPhotosToIds(
                        uploadedFiles,
                        photos,
                        PhotoType.Leader
                    )[0] || null

                return { masjidPhotoIds, leaderPhotoId }
            }),
            catchError((error) => {
                console.error('Error during file uploads:', error)
                return throwError(() => error)
            })
        )
    }

    private handleLessonsCreation(
        lessons: MasjidLessonsInterface[]
    ): Observable<{ lessonIds: string[] }> {
        if (!lessons || lessons.length === 0) {
            return of({ lessonIds: [] })
        }

        return forkJoin(
            lessons.map((lesson) =>
                this._lessonsService.createMasjidLesson(lesson).pipe(
                    map((createdLesson) => createdLesson.$id), // Extract only the ID
                    catchError((error) => {
                        console.error('Error during lessons creation:', error)
                        return throwError(() => error) // Propagate the error
                    })
                )
            )
        ).pipe(
            map((lessonIds) => ({ lessonIds })), // Convert array to object
            catchError((error) => {
                console.error('Error during lessons creation:', error)
                return of({ lessonIds: [] }) // Return an empty array in case of failure
            })
        )
    }

    loadAdminMasjids$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAdminMasjids),
            switchMap(() =>
                this._authService.isAuthenticated().pipe(
                    switchMap((auth) =>
                        this._masjidService.getMasjidsByAdminId(
                            auth.auth?.currentUser?.userID || ''
                        )
                    ),
                    map((masjids) => {
                        this._notificationService.removeLoading()
                        return loadAdminMasjidsSuccess({ masjids })
                    }),
                    catchError((error) => {
                        this._notificationService.removeLoading()
                        return of(loadAdminMasjidsFailure({ error }))
                    })
                )
            )
        )
    )

    updateExistingMasjid$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateExistingMasjid),
            withLatestFrom(this._store.select(editMasjidUpdateMasjidSelector)),
            mergeMap(
                ([
                    action,
                    {
                        deleteFiles,
                        uploadFiles,
                        newLessons,
                        updateLessons,
                        deleteLessons,
                        masjidData,
                    },
                ]) =>
                    this.processMasjidAction(
                        masjidData,
                        newLessons,
                        updateLessons,
                        deleteLessons,
                        deleteFiles,
                        uploadFiles
                    ).pipe(
                        map((result) =>
                            updateExistingMasjidSuccess({
                                updated: true,
                                masjidData:
                                    this._masjidService.convertDocumentToInterface(
                                        result
                                    ),
                                isSubmittingSave: false,
                            })
                        ),
                        catchError((error: any) => {
                            return of(
                                updateExistingMasjidFailure({
                                    error,
                                    updated: false,
                                    isSubmittingSave: false,
                                })
                            )
                        })
                    )
            ),
            untilDestroyed(this)
        )
    )

    updateExistingMasjidSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateExistingMasjidSuccess),
                tap((action) => {
                    this._notificationService.removeLoading()
                    this._notificationService.notifySuccess(
                        'Masjid data is updated successfully!'
                    )
                    setTimeout(() => {
                        this._router.navigate([
                            'masjid/details',
                            action.masjidData.id,
                        ])
                    }, 3000)
                }),
                catchError((error) => {
                    return this.handleErrors(error)
                }),
                untilDestroyed(this)
            ),
        { dispatch: false }
    )

    updateExistingMasjidFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateExistingMasjidFailure),
                tap((action) => {
                    console.log('starting effect updateExistingMasjidFailure$')
                    this._notificationService.removeLoading()
                    this._notificationService.notifyFailure(
                        'Error updating masjid data'
                    )
                    let message: string
                    if (action.error instanceof AppwriteException) {
                        message = Utils.formatAppWriteException(action.error)
                    } else message = Utils.formatException(action.error)
                    this._logService.writeLogAsync({
                        message: message,
                        logType: LogType.Error,
                    })
                }),
                catchError((error) => {
                    return this.handleErrors(error)
                }),
                untilDestroyed(this)
            ),
        { dispatch: false }
    )

    handleErrors(error: Error | AppwriteException): Observable<never> {
        let message: string
        if (error instanceof AppwriteException) {
            message = Utils.formatAppWriteException(error)
        } else message = Utils.formatException(error)
        this._logService.writeLogAsync({
            message: message,
            logType: LogType.Error,
        })
        return throwError(() => error)
    }

    private handleLessons(
        masjidData: MasjidDataInterface,
        newLessons: MasjidLessonsInterface[],
        updateLessons: MasjidLessonsInterface[],
        deleteLessons: MasjidLessonsInterface[]
    ): Observable<Models.Document> {
        return this._lessonsService
            .handleAllLessons(
                masjidData,
                newLessons,
                updateLessons,
                deleteLessons
            )
            .pipe(
                switchMap((lessonIds) => {
                    console.log(
                        'After handleLessonsAndUpdateMasjid:',
                        lessonIds
                    )
                    masjidData = {
                        ...masjidData,
                        lessonIds: lessonIds,
                    }

                    return this._masjidService.updateMasjidDocument(
                        masjidData.id,
                        masjidData
                    )
                }),
                catchError((error) => this.handleErrors(error))
            )
    }

    private processMasjidAction(
        masjidData: MasjidDataInterface,
        newLessons: MasjidLessonsInterface[],
        updateLessons: MasjidLessonsInterface[],
        deleteLessons: MasjidLessonsInterface[],
        deleteFiles: Observable<PhotoOperation>[],
        uploadFiles: Observable<PhotoOperation>[]
    ): Observable<Models.Document> {
        console.log('starting effect updateMasjid$')
        console.log(
            'Action info',
            masjidData,
            newLessons,
            updateLessons,
            deleteLessons,
            deleteFiles,
            uploadFiles
        )
        this._notificationService.loading()

        if (deleteFiles.length > 0 || uploadFiles.length > 0) {
            console.log('There are files to process')
            return forkJoin([...deleteFiles, ...uploadFiles]).pipe(
                concatMap((result) => {
                    console.log('After forkJoin/concatMap:', result)

                    if (result.length > 0) {
                        masjidData = {
                            ...masjidData,
                            ImagesIds: this.getMasjidImages(
                                masjidData.ImagesIds,
                                result
                            ),
                            leaderImageId: this.getLeaderImageId(
                                masjidData.leaderImageId,
                                result
                            ),
                        }
                    }

                    if (
                        newLessons.length > 0 ||
                        updateLessons.length > 0 ||
                        deleteLessons.length > 0
                    ) {
                        return this.handleLessons(
                            masjidData,
                            newLessons,
                            updateLessons,
                            deleteLessons
                        )
                    } else {
                        // No lessons to update or add, proceed to update masjid document
                        return this._masjidService.updateMasjidDocument(
                            masjidData.id,
                            masjidData
                        )
                    }
                }),
                catchError((error) => this.handleErrors(error))
            )
        } else if (
            newLessons.length > 0 ||
            updateLessons.length > 0 ||
            deleteLessons.length > 0
        ) {
            console.log('There are masjid lessons to process')
            // There are no deleteObservables or uploadObservables, but there are lessons to update or add
            // Step 3: Update or add masjid lessons
            return this.handleLessons(
                masjidData,
                newLessons,
                updateLessons,
                deleteLessons
            )
        } else {
            console.log('Updating masjid data...')
            // There are no deletes, uploads, or lessons to update or add
            // Simply update the masjid document
            return this._masjidService.updateMasjidDocument(
                masjidData.id,
                masjidData
            )
        }
    }

    private getMasjidImages(
        oldIds: Array<string>,
        newIds: PhotoOperation[]
    ): Array<string> {
        // Convert the existing masjidData.ImagesIds to a set
        const existingIdsSet = new Set(oldIds)

        // Process new IDs based on the operation
        newIds.forEach((item) => {
            if (item.imageType === PhotoType.Masjid) {
                if (item.operation === 'upload') {
                    existingIdsSet.add(item.id)
                } else if (item.operation === 'delete') {
                    existingIdsSet.delete(item.id)
                }
            }
        })
        console.log('masjid images ids is:', Array.from(existingIdsSet))
        // Convert the set back to an array and assign it to masjidData.ImagesIds
        return Array.from(existingIdsSet)
    }

    private getLeaderImageId(
        oldLeaderId: string | null,
        newIds: PhotoOperation[]
    ): string | null {
        const newLeaderImage = newIds.find(
            (item) =>
                item.imageType === PhotoType.Leader &&
                item.operation === 'upload'
        )

        if (newLeaderImage) {
            // If there is a new leader image uploaded, return its ID
            return newLeaderImage.id
        } else {
            // If there is no new leader image uploaded, return the old leader ID
            return oldLeaderId
        }
    }
}
