<div id="app">
  <mat-sidenav-container class="sidenav-container" [dir]="direction">
    <!-- Sidebar -->
    <mat-sidenav
      #drawer
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false"
      (mouseover)="isHover = true"
      (mouseout)="isHover = false"
      [fixedInViewport]="true"
      [class]="
        !(isHandset$ | async) && !isHover
          ? 'sidenav sidenav-minimize'
          : 'sidenav'
      "
    >
      <mat-toolbar color="primary">Menu</mat-toolbar>
      <!-- Standardized Material List -->
      <mat-list
        role="navigation"
        [attr.click]="(isHandset$ | async) ? 'drawer.toggle()' : null"
      >
        <mat-list-item routerLink="/home" routerLinkActive="isActive">
          <mat-icon matListIcon>home</mat-icon>
          <span class="text">{{ 'navbar.home' | transloco }}</span>
        </mat-list-item>

        <mat-list-item routerLink="/masjid/list" routerLinkActive="isActive">
          <mat-icon matListIcon>mosque</mat-icon>
          <span class="text">{{ 'navbar.masjids' | transloco }}</span>
        </mat-list-item>

        <mat-list-item routerLink="/news/list" routerLinkActive="isActive">
          <mat-icon matListIcon>feed</mat-icon>
          <span class="text">{{ 'navbar.news' | transloco }}</span>
        </mat-list-item>

        <mat-list-item routerLink="/about" routerLinkActive="isActive">
          <mat-icon matListIcon>diversity_3</mat-icon>
          <span class="text">{{ 'navbar.about' | transloco }}</span>
        </mat-list-item>

        <mat-list-item routerLink="/contact" routerLinkActive="isActive">
          <mat-icon matListIcon>call</mat-icon>
          <span class="text">{{ 'navbar.contect' | transloco }}</span>
        </mat-list-item>

        <!-- Login & Register as List Items -->
        <mat-list-item *ngIf="!(isLoggedIn$ | async)" routerLink="/auth/login">
          <mat-icon matListIcon>login</mat-icon>
          <span class="text">{{ 'navbar.login' | transloco }}</span>
        </mat-list-item>

        <mat-list-item
          *ngIf="!(isLoggedIn$ | async)"
          routerLink="/auth/register"
        >
          <mat-icon matListIcon>create</mat-icon>
          <span class="text">{{ 'navbar.register' | transloco }}</span>
        </mat-list-item>
      </mat-list>
      <!-- Admin Section -->
      <mat-divider *ngIf="isAdmin$ | async"></mat-divider>
      <mat-list *ngIf="isAdmin$ | async" role="navigation">
        <mat-list-item
          routerLink="/masjid/dashboard"
          routerLinkActive="isActive"
        >
          <mat-icon matListIcon>admin_panel_settings</mat-icon>
          <span class="text">{{ 'navbar.admin_dashboard' | transloco }}</span>
        </mat-list-item>
        <mat-list-item routerLink="/masjid/create" routerLinkActive="isActive">
          <mat-icon matListIcon>add_circle</mat-icon>
          <span class="text">{{ 'navbar.create_masjid' | transloco }}</span>
        </mat-list-item>
      </mat-list>
    </mat-sidenav>

    <!-- Main Content -->
    <mat-sidenav-content class="main-content background-light-image">
      <app-header
        (toggleSideNavEvent)="drawer.toggle()"
        (direction)="setDirection($event)"
      ></app-header>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
