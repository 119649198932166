import { provideRouter } from '@angular/router';
import { importProvidersFrom } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { provideState, StoreModule } from '@ngrx/store';
import { reducerNews } from './store/reducers';
import { materialProviders } from '../shared/providers/material.providers';
import { initialNewsState } from './interfaces/news.state.interface';
import { newsRoutes } from './news-routes';

export const newsProviders = [
  provideRouter(newsRoutes),
  importProvidersFrom(
    CommonModule,
    TranslocoModule,
  ),
  provideState('news', reducerNews, {initialState: initialNewsState}),
  ...materialProviders
];
