import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, concatMap, finalize, of, tap } from 'rxjs'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { AuthService } from '../../../shared/services/auth.service'
import { logoutUserAction } from '../actions/login.actions'

@Injectable()
export class LogoutEffects {
    logoutUserSuccessful$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(logoutUserAction),
                tap(() => this._notification.loading()),
                concatMap(() =>
                    this._authService.logout().pipe(
                        tap(() => {
                            this._notification.removeLoading();
                            this._notification.notifySuccess(
                                'User Logout successfully. Redirecting you to home page in 3 seconds.'
                            );
                        }),
                        catchError((err) => {
                            console.error('User logout error:', err);
                            this._notification.removeLoading();
                            this._notification.notifyFailure(err.message);
                            return of(err);
                        }),
                        finalize(() => {
                            console.log('Redirecting to home in 3 seconds...');
                            setTimeout(() => {
                                this._router.navigateByUrl('/');
                            }, 3000); // 3-second delay before redirect
                        })
                    )
                )
            ),
        { dispatch: false }
    );

    constructor(
        private _actions$: Actions,
        private _router: Router,
        private _authService: AuthService,
        private _notification: NotificationService
    ) {}
}
