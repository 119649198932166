import { Injectable, ErrorHandler, NgZone, Injector } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { GlobalError } from '../interfaces/global-error.interface'
import { LogService } from './log.service'
import { Store, select } from '@ngrx/store'
import { take } from 'rxjs'
import { currentUserSelector } from 'src/app/auth/store/selectors'
import { AppState } from 'src/app/store/app.state'
import Utils from './utils'
import { LogEntry, LogType } from '../interfaces/log.interface'

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
    private store?: Store<AppState>

    constructor(
        private router: Router,
        private ngZone: NgZone,
        private logService: LogService,
        private injector: Injector
    ) {}

    handleError(error: any): void {
        console.error('Global Error Handler:', error)
        if (!this.store) {
            this.store = this.injector.get(Store)
        }
        debugger
        this.store
            .pipe(select(currentUserSelector), take(1))
            .subscribe((user) => {
                const userId = user?.userID || null
                const correlationId = Utils.generateCorrelationId(userId)

                const errorDetails: GlobalError = {
                    message: error.message || 'Unknown Error',
                    statusCode: error.status || 500,
                    stackTrace: error.stack || 'No stack trace available',
                    correlationId,
                }
                debugger
                this.logError(errorDetails)

                this.ngZone.run(() => {
                    this.router.navigate(['/error'], {
                        queryParams: errorDetails,
                    })
                })
            })
        // const correlationId = Utils.generateCorrelationId(this.userId)
        // debugger
        // const errorDetails: GlobalError = {
        //     message: error.message || 'Unknown Error',
        //     statusCode: error.status || 500,
        //     stackTrace: error.stack || 'No stack trace available',
        //     correlationId,
        // }
        // this.logError(errorDetails)
        // this.ngZone.run(() => {
        //     this.router.navigate(['/error'], { queryParams: errorDetails })
        // })
    }

    isValidErrorParams(route: ActivatedRouteSnapshot): boolean {
        const params = route.queryParams

        if (!params || Object.keys(params).length === 0) return false

        // Required parameters
        if (!params['message'] || typeof params['message'] !== 'string')
            return false
        if (!params['statusCode'] || isNaN(Number(params['statusCode'])))
            return false

        // Optional parameters
        if (params['stackTrace'] && typeof params['stackTrace'] !== 'string')
            return false
        if (
            params['correlationId'] &&
            typeof params['correlationId'] !== 'string'
        )
            return false

        return true
    }

    private logError(errorDetails: GlobalError) {
        const message = Utils.handleErrorMessage(
            errorDetails,
            errorDetails.correlationId
        )
        const logEntry: LogEntry = {
            message: message,
            logType: LogType.Error,
        }

        this.logService.writeLogAsync(logEntry)
    }
}
