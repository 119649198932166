import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, of, tap } from 'rxjs'
import { registerSuccessAction } from '../actions/register.actions'

@Injectable()
export class RegisterEffects {
    redirectAfterRegisterSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(registerSuccessAction),
                tap((currentUser) => {
                    this._router.navigateByUrl('/')
                }),
                catchError((err) => {
                    console.log('register effect redirect error: ', err)
                    return of(err)
                })
            ),
        { dispatch: false } // this is important, otherwise browser will hange
    )

    constructor(private _actions$: Actions, private _router: Router) {}
}
