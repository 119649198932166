import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
    FormGroup,
    FormBuilder,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms'
import { MatCardModule } from '@angular/material/card'
import {
    MatError,
    MatFormFieldModule,
    MatLabel,
} from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store, select } from '@ngrx/store'
import { Observable, catchError, map, of, tap } from 'rxjs'
import { ContractData } from 'src/app/shared/interfaces/contact.data.interface'
import { CurrentUserInterface } from 'src/app/shared/interfaces/currentUser.interface'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { ContactService } from 'src/app/shared/services/contact.service'
import { LogService } from 'src/app/shared/services/log.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { AppState } from 'src/app/store/app.state'
import { selectAuthState } from 'src/app/store/selectors'
@UntilDestroy()
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: true,
    imports: [
        MatError,
        MatFormFieldModule,
        MatLabel,
        MatCardModule,
        ReactiveFormsModule,
        CommonModule,
        TranslocoModule,
        MatInputModule
    ],
})
export class ContactComponent implements OnInit {
    formData: FormGroup
    isSubmitting$: Observable<boolean> = of(false)
    userData: CurrentUserInterface | null = null
    successMessage: string = ''
    errorMessage: string = ''

    constructor(
        private builder: FormBuilder,
        private logger: LogService,
        private contactService: ContactService,
        private notification: NotificationService,
        private _store: Store<AppState>
    ) {
        logger.writeLogAsync({
            message: 'contact component started!',
            logType: LogType.Info,
        })
    }

    ngOnInit() {
        this._store
            .pipe(
                select(selectAuthState),
                map((auth) => {
                    console.info('current user data is:', auth)
                    this.userData = auth?.currentUser

                    const fullName = `${this.userData?.firstName || ''} ${
                        this.userData?.lastName || ''
                    }`

                    this.formData = this.builder.group({
                        name: [fullName.trim(), [Validators.required]],
                        emailAddress: [
                            this.userData?.email || '',
                            [
                                Validators.compose([
                                    Validators.required,
                                    Validators.email,
                                ]),
                            ],
                        ],
                        message: ['', [Validators.required]],
                    })
                }),
                untilDestroyed(this)
            )
            .subscribe()

        console.info('User data or email is available!', this.userData)
    }

    onSubmit(form: Event) {
        this.errorMessage = ''
        this.successMessage = ''

        if (this.formData.valid) {
            let data: ContractData
            if (this.userData !== null) {
                data = {
                    name: `${this.userData.firstName!} ${this.userData
                        .lastName!}`,
                    email: this.userData.email,
                    message: this.formData.value.message,
                    userId: this.userData.userID,
                    registered: true,
                }
            } else {
                data = {
                    name: this.formData.value.name,
                    email: this.formData.value.emailAddress,
                    message: this.formData.value.message,
                    userId: null,
                    registered: false,
                }
            }

            console.log('Form Data: ', data)
            this.contactService
                .saveUserContactMessage(data)
                .pipe(
                    tap((response) => {
                        console.log(
                            'Your message was sent to the masjids info team successfully!',
                            response
                        )
                        this.logger.writeLogAsync({
                            logType: LogType.Debug,
                            message:
                                'Your message was sent to the masjids info team successfully!',
                        })
                        this.notification.notifySuccess(
                            'Your message was sent to the masjids info team successfully!'
                        )
                        this.formData.reset()
                        this.successMessage =
                            'Your message was sent to the masjids info team successfully!'
                    }),
                    catchError((error) => {
                        console.error(
                            'Error saving user message, please try again after sometime.',
                            error
                        )
                        this.notification.notifyFailure(
                            'Error saving user message, please try again after sometime.'
                        )
                        this.errorMessage =
                            'Error saving user message, please try again after sometime.'
                        return of(null)
                    })
                )
                .subscribe()
        } else {
            Object.values(this.formData.controls).forEach((control) => {
                if (!control.valid) {
                    control.markAsTouched()
                }
            })
        }
    }
}
