import { CommonModule } from '@angular/common';
import { Component } from '@angular/core'
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    standalone: true,
    imports: [MatCardModule, CommonModule]
})
export class PageNotFoundComponent {}
