<mat-card class="error-card">
  <mat-card-header>
    <mat-card-title>
      <mat-error>
        <h1>{{ 'error.title' | transloco }}<mat-icon>error</mat-icon></h1>
      </mat-error>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>{{ isDev ? error.message : ('error.message' | transloco) }}</p>
    <div *ngIf="hasErrorDetails">
      <p>
        <strong>{{ 'error.status_code' | transloco }}:</strong>
        {{ error.statusCode }}
      </p>
      <div *ngIf="isDev">
        <p>
          <strong>{{ 'error.error_message' | transloco }}:</strong>
          {{ error.message }}
        </p>
        <p>
          <strong>{{ 'error.stack_trace' | transloco }}:</strong> <br />
          <small>{{ error.stackTrace }}</small>
        </p>
        <p>
          <strong>{{ 'error.correlation_id' | transloco }}:</strong>
          {{ error.correlationId }}
        </p>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="reportIssue()"
      >
        <mat-icon>bug_report</mat-icon>{{ 'error.report_issue' | transloco }}
      </button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="goHome()"
      >
        <mat-icon>home</mat-icon>{{ 'error.go_home' | transloco }}
      </button>
  </mat-card-actions>
</mat-card>
