import { ErrorHandler, importProvidersFrom, isDevMode } from '@angular/core'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { provideRouter } from '@angular/router'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideStore } from '@ngrx/store'
import { provideEffects } from '@ngrx/effects'
import { provideStoreDevtools } from '@ngrx/store-devtools'
import { ToastrModule } from 'ngx-toastr'
import { NgxSpinnerModule } from 'ngx-spinner'

import { appRoutes } from './app-routes'
import { rootReducer } from './store/app.reducer'
import { LoginEffects } from './auth/store/effects/login.effects'
import { LogoutEffects } from './auth/store/effects/logout.effect'
import { RecoverEffects } from './auth/store/effects/recover.effects'
import { RegisterEffects } from './auth/store/effects/register.effects'

// Material UI
import { LayoutModule } from '@angular/cdk/layout'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatMenuModule } from '@angular/material/menu'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { MatCardModule } from '@angular/material/card'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatListModule } from '@angular/material/list'
import { ErrorHandlerService } from './shared/services/error-handler.service'

export const appProviders = [
    provideRouter(appRoutes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideStore(
        { appState: rootReducer },
        {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictActionSerializability: true,
                strictStateSerializability: true,
            },
        }
    ),
    provideEffects([
        LoginEffects,
        LogoutEffects,
        RegisterEffects,
        RecoverEffects,
    ]),
    importProvidersFrom(
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            closeButton: true,
        }),
        NgxSpinnerModule,
        LayoutModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        MatSidenavModule,
        MatInputModule,
        MatRadioModule,
        MatCardModule,
        MatGridListModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatProgressBarModule,
        MatListModule
    ),
    ...(isDevMode()
        ? [
              provideStoreDevtools({
                  maxAge: 25,
                  logOnly: true,
                  autoPause: true,
                  trace: true,
              }),
          ]
        : []),
    { provide: ErrorHandler, useClass: ErrorHandlerService },
]
