import { Injectable } from '@angular/core'
import {
    CanActivate,
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router'
import { ErrorHandlerService } from '../services/error-handler.service'

@Injectable({
    providedIn: 'root',
})
export class ErrorPageGuard implements CanActivate {
    constructor(
        private errorHandlerService: ErrorHandlerService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this.errorHandlerService.isValidErrorParams(route)) {
            return true
        }

        console.warn('Invalid error parameters detected. Redirecting to Home.')
        this.router.navigate(['/home'])
        return false
    }
}
