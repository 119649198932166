import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, of, tap } from 'rxjs'
import { recoverSuccessAction } from '../actions/recover.actions'

@Injectable()
export class RecoverEffects {
    redirectAfterRecoverPasswordSuccessfully$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(recoverSuccessAction),
                tap((request) => {
                    this._router.navigateByUrl('/auth/login')
                }),
                catchError((err) => {
                    console.log('after recover redirect error: ', err)
                    return of(err)
                })
            ),
        { dispatch: false }
    )

    constructor(private _actions$: Actions, private _router: Router) {}
}
