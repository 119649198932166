import { provideRouter } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { authRoutes } from './auth.routes';
import { reducerAuth } from './store/reducers';
import { initialAuthState } from './interfaces/authState.interface';
import { provideState } from '@ngrx/store';

export const authProviders = [
  provideRouter(authRoutes), 
  provideState('auth', reducerAuth, { initialState: initialAuthState }),
  AuthService,
];
