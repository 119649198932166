import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, of, switchMap, tap } from 'rxjs'
import { UserAddressDocumentService } from 'src/app/shared/services/user-address.service'
import { AuthService } from '../../../shared/services/auth.service'
import { loginSuccessAction } from '../actions/login.actions'

@Injectable()
export class LoginEffects {
    redirectAfterLoginSuccessful$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(loginSuccessAction),
                map((action) => action.auth),
                concatMap((auth) => {
                    return this._userAddressService
                        .findUserAddressDocument(auth.currentUser?.userID!)
                        .pipe(
                            switchMap((doc) => {
                                // check if user has address
                                // set in the preferences boolean has_address to true
                                if (doc.total > 0) {
                                    return this._auth.updateUserPreferences({
                                        ...auth.userPreferences,
                                        has_address: true,
                                    })
                                } else {
                                    return this._auth.updateUserPreferences({
                                        ...auth.userPreferences,
                                        has_address: false,
                                    })
                                }
                            })
                        )
                }),
                tap((prefs) => {
                    console.log('executed findUserAddressDocument:', prefs)

                    this._router.navigateByUrl('/')
                }),
                catchError((err) => {
                    // set in the preferences boolean has_address to false
                    console.log('After login redirect error: ', err)
                    return of(err)
                })
            ),
        { dispatch: false } // this is important, otherwise browser will hange
    )

    constructor(
        private _actions$: Actions,
        private _router: Router,
        private _userAddressService: UserAddressDocumentService,
        private _auth: AuthService
    ) {}
}
