import { CommonModule } from '@angular/common'
import { Component, isDevMode, OnInit } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatError } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { ActivatedRoute, Router, RouterModule } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'
import { GlobalError } from 'src/app/shared/interfaces/global-error.interface'

@Component({
    selector: 'app-global-error',
    templateUrl: './global-error.component.html',
    styleUrls: ['./global-error.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        MatError,
        MatIconModule,
        CommonModule,
        RouterModule,
        MatButtonModule,
        TranslocoModule
    ],
})
export class GlobalErrorComponent implements OnInit {
    constructor(private route: ActivatedRoute, private router: Router) {}
    error: GlobalError = { statusCode: 0, message: '', stackTrace: '' }
    hasErrorDetails: boolean = false
    isDev: boolean = isDevMode();
    userFriendlyMessage: string = 'An unexpected error occurred. Please try again later.';

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: any) => {
            if (Object.keys(params).length > 0) {
                this.hasErrorDetails = true
                this.error = params
            }
        })
    }

    reportIssue() {
        const githubIssueUrl = `https://github.com/your-repo/issues/new?title=Bug%20Report&body=${encodeURIComponent(
            JSON.stringify(this.error, null, 2)
        )}`
        window.open(githubIssueUrl, '_blank')
    }

    goHome() {
        this.router.navigate(['/home'])
    }
}
