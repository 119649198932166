import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'

import { Member, members } from './Members'
import { TeamMemberComponent } from './team-member/team-member.component'
import { CommonModule } from '@angular/common'

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    standalone: true,
    imports: [TeamMemberComponent, CommonModule]
})
export class AboutComponent {
    members: Member[] = members

    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(
            map((result) => result.matches),
            shareReplay()
        )

    constructor(private breakpointObserver: BreakpointObserver) {}
}
