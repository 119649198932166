import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, OnInit } from '@angular/core'
import { EMPTY, Observable, throwError } from 'rxjs'
import {
    catchError,
    distinctUntilChanged,
    map,
    shareReplay,
    tap,
} from 'rxjs/operators'

import { Direction } from '@angular/cdk/bidi'
import { Store } from '@ngrx/store'
import { AppwriteException } from 'appwrite'
import { refreshAuthenticatedUserDataAction } from 'src/app/auth/store/actions/refresh.actions'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { AuthService } from 'src/app/shared/services/auth.service'
import { LogService } from 'src/app/shared/services/log.service'
import Utils from 'src/app/shared/services/utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatSidenavModule } from '@angular/material/sidenav'
import { CommonModule } from '@angular/common'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { TranslocoModule } from '@ngneat/transloco'
import { HeaderComponent } from '../../header/header.component'
import { FooterComponent } from '../../footer/footer.component'
import { RouterModule } from '@angular/router'
import { MatFormFieldModule } from '@angular/material/form-field'
import { ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'

@UntilDestroy()
@Component({
    selector: 'app-custom-layout',
    templateUrl: './custom-layout.component.html',
    styleUrls: ['./custom-layout.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatSidenavModule,
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        TranslocoModule,
        HeaderComponent,
        FooterComponent,
        RouterModule,

    ],
})
export class CustomLayoutComponent implements OnInit {
    public direction: Direction = 'ltr'
    isHover: boolean = false
    isHandset$: Observable<boolean>
    isLoggedIn$: Observable<boolean>
    isUserEmailVerified$: Observable<boolean>
    isAdmin$: Observable<boolean>

    constructor(
        private breakpointObserver: BreakpointObserver,
        private _authService: AuthService,
        private store: Store,
        private _loggerService: LogService
    ) {
        this.isLoggedIn$ = this._authService.isLoggedIn
        this.isUserEmailVerified$ = this._authService.isUserEmailVerified
        this.isAdmin$ = this._authService.isAdmin
        this.isHandset$ = this.breakpointObserver
            .observe(Breakpoints.Handset)
            .pipe(
                map((result) => result.matches),
                distinctUntilChanged(),
                shareReplay({ bufferSize: 1, refCount: true })
            )

        this._loggerService.writeLogAsync({
            message: 'Setting up the layout!',
            logType: LogType.Debug,
        })
    }

    ngOnInit(): void {
        this.isLoggedIn$.pipe(untilDestroyed(this)).subscribe((isLoggedIn) => {
            console.log('User logged in:', isLoggedIn)
        })
        this.isAdmin$.pipe(untilDestroyed(this)).subscribe((admin) => {
            console.log('User is Admin:', admin)
        })
        this.isUserEmailVerified$
            .pipe(untilDestroyed(this))
            .subscribe((isVerified) => {
                console.log('Email verified:', isVerified)
            })
        this.isHandset$.subscribe((isHandset) =>
            console.log('isHandset$', isHandset)
        )

        this._authService
            .isAuthenticated()
            .pipe(
                tap((response) => {
                    this.store.dispatch(
                        refreshAuthenticatedUserDataAction({ auth: response })
                    )
                }),
                catchError((error: any) => {
                    if (error instanceof AppwriteException) {
                        const message = Utils.formatAppWriteException(error)
                        this._loggerService.writeLogAsync({
                            message: message,
                            logType: LogType.Error,
                        })
                    }
                    // Continue to propagate the error up the observable chain
                    return EMPTY
                })
            )
            .subscribe()
    }

    setDirection(direction: Direction) {
        if (this.direction !== direction) {
            this.direction = direction
        }
    }
}
