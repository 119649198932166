import { importProvidersFrom } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { provideRouter, RouterModule, Routes } from '@angular/router'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { TranslocoModule } from '@ngneat/transloco'
import { provideEffects } from '@ngrx/effects'
import { provideState, StoreModule } from '@ngrx/store'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSelectModule } from '@angular/material/select'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'

import { AuthenticatedGuard } from 'src/app/shared/guards/authenticated.guard'
import { MasjidEffects } from './store/effects/masjid.effects'
import { reducerEditMasjid } from './store/reducers/edit-masjid.reducer'
import { reducerMasjid } from './store/reducers/masjid.reducer'
import { reducerCreateMasjid } from './store/reducers/create.masjid.reducer'
import {
    initialCreateMasjidState,
    initialEditMasjidState,
    initialMasjidState,
} from './interfaces/masjid.state.interface'
import { masjidRoutes } from './masjid.routes'

export const masjidProviders = [
    provideRouter(masjidRoutes),
    provideState('masjid', reducerMasjid, { initialState: initialMasjidState }),
    provideState('editMasjid', reducerEditMasjid, {
        initialState: initialEditMasjidState,
    }),
    provideState('createMasjid', reducerCreateMasjid, {
        initialState: initialCreateMasjidState,
    }),
    provideEffects(MasjidEffects),
    importProvidersFrom(
        CommonModule,
        ReactiveFormsModule,
        DragDropModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatListModule,
        MatToolbarModule,
        MatProgressBarModule,
        MatSelectModule,
        MatDividerModule,
        MatTableModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        NgxMaterialTimepickerModule.setOpts('en-US'),
        TranslocoModule
    ),
    AuthenticatedGuard,
]
